.modalContent {
  opacity: 1;

  :global {
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 20px !important;
      background: #fff;
      border-radius: 16px !important;
    }

    .ant-modal-close {
      display: none;
    }

    .ant-modal-footer {
      display: none !important;
    }
  }
}

.header {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  color: #121212;
}

.confirmTip {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgb(125, 125, 125);
  word-break: break-word;

  // text-align: center;
}

.footerContent {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
