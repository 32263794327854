.modalContent {
  opacity: 1;

  :global {
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 20px !important;
      background: #fff;
      border-radius: 16px !important;
    }

    .ant-modal-close {
      display: none;
    }

    .ant-modal-footer {
      display: none !important;
    }
  }
}

.header {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  color: var(--text-icon-08-black, #000);
}

.confirmTip {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  color: var(--text-icon-063-b-3-c-3-c, #3b3c3c);

  // text-align: center;
}

.footerContent {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
